import React, { useState } from 'react'

import { Props } from './Form.types'

const Form: React.FC<Props> = ({
  submit,
  submitting
}) => {
  const [email, setEmail] = useState('')

  return (
    <form
      className='mb-4'
      onSubmit={(e) => {
        e.preventDefault()
        submit(email)
      }}
    >
      <div className='d-flex flex-column flex-md-row align-items-stretch' style={{ gap: '20px' }}>
        <div className='flex-grow-1'>
          <label className='sr-only'>Email address</label>
          <input
            required
            type='email'
            className='email-input'
            placeholder='Enter your email address'
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <button 
            className='submit-button w-100' 
            type='submit' 
            disabled={submitting}
          >
            {submitting ? (
              <span className='spinner-border spinner-border-sm me-2' role='status' aria-hidden='true' />
            ) : null}
            Join the Waitlist
          </button>
        </div>
      </div>
    </form>
  )
}

export default Form
