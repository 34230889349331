import React, { useContext } from 'react'
import { SocialIcon } from 'react-social-icons'
import Form from '../Form/Form'
import { AppContext } from '../../context/appContext'
import Error from '../Error/Error'
import { register } from '../../actions/actions'

const Main: React.FC = () => {
  const {
    dispatch,
    state: {
      registered,
      error,
      hasError,
      submitting
    }
  } = useContext(AppContext)

  const submit = (email: string) => {
    register(email, dispatch)
  }

  return (
    <main>
      <div className='container'>
        <div className='logo-container text-center mb-5'>
          <img 
            src='./img/logo.svg' 
            alt='Findaproperty search for houses and flats for sale and rent' 
            width='200' 
            className='text-center'
          />
        </div>
        
        <h1 className='h4 mb-4 font-weight-bold'>Coming Soon: Mauritius' Ultimate Real Estate Platform! 🏡</h1>
        
        <div className='mb-4'>
          <p className='lead'>We're building <span className='brand-name'>findaproperty.mu</span>, a next-generation real estate portal designed to connect buyers and sellers with the best opportunities in Mauritius.</p>
          
          <p className='mb-4'>🚀 Launching Soon! Whether you're looking to sell your property at the best price or find your dream home, our platform will provide expert guidance, market insights, and seamless transactions.</p>
          
          <p className='mb-4'>📩 Stay Updated! Sign up for exclusive early access, property insights, and launch announcements.</p>
          
          <p className='mb-4'>
            💡 Want to know how to buy or sell smarter?{' '}
            <a 
              className='font-weight-bold text-primary' 
              href='https://www.findaproperty.mu/news/' 
              title='Find out about the properties market in mauritius'
            >
              Explore our blog
            </a>
            {' '}or reach out at{' '}
            <a 
              href="mailto:gerald@findaproperty.mu" 
              className='text-primary font-weight-bold'
            >
              gerald@findaproperty.mu
            </a>
          </p>
        </div>

        {hasError && <Error error={error} />}
        
        {registered ? (
          <div className='alert alert-success' role='alert'>
            Thank you very much for signing up! We'll keep you updated.
          </div>
        ) : (
          <>
            <Form
              submit={submit}
              submitting={submitting}
            />
            <div className='social-icons'>
              <div className='social-icon'>
                <SocialIcon 
                  url='https://twitter.com/findapropertymu' 
                  network='x' 
                  bgColor='#343a40' 
                  fgColor='#ffffff' 
                  style={{ height: 32, width: 32 }}
                />
              </div>
              <div className='social-icon'>
                <SocialIcon 
                  url='https://www.facebook.com/Findapropertymu' 
                  network='facebook' 
                  bgColor='#343a40' 
                  fgColor='#ffffff' 
                  style={{ height: 32, width: 32 }}
                />
              </div>
              <div className='social-icon'>
                <SocialIcon 
                  url='https://www.instagram.com/Findapropertymu/' 
                  network='instagram' 
                  bgColor='#343a40' 
                  fgColor='#ffffff' 
                  style={{ height: 32, width: 32 }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </main>
  )
}

export default Main
